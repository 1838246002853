@import "styles/includes";

.container {
  display: block;
}

.featuredList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -1rem;
  margin-left: -1rem;
}

.col {
  padding-right: 1rem;
  padding-left: 1rem;
}

.avatar {
  width: 7rem;
  height: 7rem;
}
