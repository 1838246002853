@import "styles/includes";

.hero {
  position: relative;
  // min-height: 46rem;

  @include media-breakpoint-up(md) {
    min-height: 30rem;
  }
  @include media-breakpoint-up(lg) {
    min-height: 35rem;
  }
  // background: transparent center center no-repeat;
  // background-image: url("/static/images/home-hero-bg.svg");
  // background-size: contain;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 50%;
    content: "";
  }

  &::before {
    top: 0;
    background: linear-gradient(183deg, #fff 50%, #f4f4f4 50%);
  }
  &::after {
    top: 50%;
    background: linear-gradient(177deg, #f4f4f4 50%, #fff 50%);
  }

  .left {
    width: 40%;
  }

  .right {
    width: 60%;
  }

  img {
    max-width: 100%;
    max-height: 35rem;
  }

  @include media-breakpoint-down(sm) {

    &::before {
      height: 22%;
      background: linear-gradient(183deg, #fff 50%, #f4f4f4 50%);
    }
    &::after {
      top: 22%;
      height: 50%;
      background: linear-gradient(177deg, #f4f4f4 50%, #fff 50%);
    }

    .left,
    .right {
      position: static;
    }

    .left {
      top: 0;
      right: 0;
      left: auto;
      width: 50%;
      img {
        height: auto;
      }
    }
    .right {
      width: 100%;
      padding: 0 1rem;
    }
  }
}

.inner {
  position: relative;
  width: 100%;
  padding: 0 1rem;

  @include media-breakpoint-down(sm) {
    width: 32.5rem;
    height: 100%;
    margin: 0 auto;
  }
}

.h1 {
  font-size: 2.2rem;
  line-height: 1.1;
  // text-shadow: rgba(242, 66, 62, .3) -4px 3px 1px, rgba(56, 14, 185, .1) -8px 5px 1px;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 2.85rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 3.5rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 5vw;
  }
}

.catch {
  font-size: 1.25rem;
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.75rem;
  }
}

.h2 {
  font-size: 2.5rem;
}

.lead {
  font-size: 1rem;
}

.hr {
  width: 18.75rem;
  max-width: 100%;
  margin: auto;
  border-bottom: 1px solid $g300;
}

.featured {
  h3 {
    font-weight: 700;
  }
}

.mediaCover {
  width: 38.2%;
  max-width: 12rem;
  margin-right: 2rem;

  :global(img) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @include media-breakpoint-up(md) {
    width: 30%;
    max-width: none;
  }
}

.featuredFirst {
  h3 {
    font-size: 1.75rem;
    font-weight: 700;
  }
  figure {
    width: 100%;
    aspect-ratio: 1 / 1;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.userarea {
  font-size: .875rem;
  line-height: 1;
}

@include media-breakpoint-up(md) {
  .featuredFirst {
    flex: 0 0 auto;
    width: 38.2%;
    margin-right: 4rem;

    figure {
      height: auto;
    }
  }
  .featuredInner {
    position: sticky;
    top: 1rem;
  }
  .featured {
    flex: 1 1 auto;
  }
}

.featuredList {
  flex-wrap: wrap;

  figure {
    // height: 12rem;
    display: block;
    background-color: #fff;
    :global(img) {
      // object-fit: cover;
      width: 100%;
      height: auto;
    }
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
  }
}

.col {
  width: 50%;
  padding: 1rem;

  @include media-breakpoint-up(md) {
    width: 25%;
  }
}


.featureFirstWaveform {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
