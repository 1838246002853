// stylelint-disable scss/dollar-variable-default

// colors
// -----------------------------------

// gray
$g100: #f1f5f9;
$g200: #e2e8f0;
$g300: #cbd5e1;
$g400: #94a3b8;
$g500: #64748b;
$g600: #475569;
$g700: #334155;
$g800: #1e293b;
$g900: #0f172a;
$gish: #252b33;
$gish-light: $g600;

// check color palet http://paletton.com/#uid=5010r0knOun8ORbh-CntqoGA9gr
// theme
$primary: #f2423e;
$brand-primary: $primary;

$text-color: $g900;
$link-color: $g900;
$link-hover-color: $g600;
$black: $g900;
$white: #fff;
$gray: $g600;
$gray-dark: $g800;
$gray-light: $g400;

$brand-twitter: #55acee;
$brand-facebook: #4c70ba;
$brand-instagram: #e1306c;
$brand-youtube: #f00;
$brand-google: #4285f4;

$color-notice: #f28c3e;
$color-warning: #c51510;
$color-info: #6772e5;
$color-success: #2bc5b1;

// break points
$screen-xs: 0;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$container-max-widths: (
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
) !default;
$grid-breakpoints: (
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl
) !default;
$grid-columns: 12;
// spacers
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;


// zIndex
$z1: 100;
$z2: 200;
$z3: 300;
$z4: 400; // navbar, player
$z5: 500; // dropdown, tooltip
$z6: 600; // modal backdrop
$z7: 700; // modal

// stylelint-disable value-keyword-case
$font-family-base: "Lato", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif;
$font-family-monospace: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
$oswald: "Oswald", sans-serif;
// stylelint-enable value-keyword-case

$enable-hover-media-query:  false;

$grid-gutter-width: 1rem;

$transition-base: all .2s ease-in-out;

$navbar-height: 3.5rem !default;

$box-shadow-sm: 0 5px 10px rgba($black, .15) !default;
$box-shadow:    0 10px 20px rgba($black, .15) !default;
$box-shadow-lg: 0 20px 50px rgba($black, .15) !default;
